import { Box, Card, CardBody, CardProps, Heading, Stack, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

import theme from '../../theme';

export interface PageControllerProps {
  /** Optional props to pass to the outer-most `Card` component wrapping the page controller. */
  cardProps?: CardProps;
  /** Children to be rendered within the controller. */
  children?: ReactNode;
  /** Line of text to display immediately below the title. */
  subtitle?: string;
  /** Title for this page. */
  title: string;
}

/**
 * This component should be used as the header/title on internal pages (i.e. those accessible after login) which are not
 * associated with a single project. On internal pages that are associated with a project, e.g. Progress Tracking, the
 * `ControlCenter` component should be used. Since this component currently renders an `h1` in HTML for the required
 * title, subsequent heading levels should take this into account.
 */
const PageController = (props: PageControllerProps) => {
  const { cardProps, children, subtitle, title } = props;

  return (
    <Card {...cardProps}>
      <CardBody>
        <Stack direction={{ base: 'column', lg: 'row' }}>
          <Box>
            <Heading as="h1" size="lg">
              {title}
            </Heading>
            {subtitle && (
              <Text color={theme.colors.brand.gray[600]} textStyle="detail">
                {subtitle}
              </Text>
            )}
          </Box>
          {children && (
            <Stack
              alignItems={{ base: 'normal', lg: 'center' }}
              direction={{ base: 'column', lg: 'row' }}
              marginInlineStart={{ base: 0, lg: 'auto' }}
            >
              {children}
            </Stack>
          )}
        </Stack>
      </CardBody>
    </Card>
  );
};

export default PageController;
