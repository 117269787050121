import { Box, Button, ButtonProps, VisuallyHidden } from '@chakra-ui/react';
import { MouseEvent, ReactNode } from 'react';

import theme from '../../theme';

export interface FloorNameCellProps {
  /** Optional props for the button element. */
  buttonProps?: ButtonProps;
  /** Children to be rendered within the button. Should be the name of the floorplan. */
  children?: ReactNode;
  /**
   * Flag indicating whether or not this cell has momentum. A value of `true` will be interpreted as the cell having
   * momentum, `false` as possible risk indicators having been identified, and `undefined` as indeterminate.
   */
  hasMomentum?: boolean | null;
  /** When the cell is not within the summary row, it is rendered as an anchor tag with this `href` property. */
  href?: string;
  /** Flag indicating whether or not the current cell is part of the summary row. */
  isSummaryCell?: boolean;
  /** Optional handler to fire when the user clicks the cell. */
  onClick?: () => void;
}

/**
 * Renders either some provided `children` (if `isSummaryCell` is passed) or a link styled as a button. The second case
 * is expected to be used most often for floorplan names.
 */
const FloorNameCell = (props: FloorNameCellProps) => {
  const { buttonProps, children, hasMomentum, href, isSummaryCell, onClick } = props;

  if (isSummaryCell) {
    return (
      <Box backgroundColor="transparent" flex={1} padding="0.75rem" textStyle="detail" textTransform="uppercase">
        {children}
      </Box>
    );
  }

  // Using an <a> tag allows users to interact normally with the link (e.g. being able to right click and open in a new
  // tab). Preventing the default behavior allows us to perform a client-side navigation.
  // Chakra is convinced this is a `MouseEventHandler<HTMLButtonElement>`, but it's wrong.
  const handleClick = (event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    if (event.ctrlKey || event.metaKey) {
      return;
    }

    event.preventDefault();
    onClick?.();
  };

  const sharedButtonProps: ButtonProps = {
    backgroundColor: theme.colors.brand.gray[50],
    border: 'none',
    color: `${theme.colors.brand.gray[900]} !important`,
    flex: 1,
    fontSize: '0.875rem !important',
    justifyContent: 'flex-start',
    onClick: handleClick,
    padding: '0.75rem',
    size: 'sm',
    textTransform: 'uppercase',
    variant: 'outline',
    _hover: {
      backgroundColor: theme.colors.brand.gray[100],
      color: `${theme.colors.brand.gray[900]} !important`,
    },
    ...buttonProps,
  };

  if (href) {
    return (
      <Button as="a" href={href} {...sharedButtonProps}>
        {children}
        {hasMomentum === true && <VisuallyHidden>Positive momentum</VisuallyHidden>}
        {hasMomentum === false && <VisuallyHidden>Delay warning</VisuallyHidden>}
      </Button>
    );
  }

  return (
    <Button {...sharedButtonProps}>
      {children}
      {hasMomentum === true && <VisuallyHidden>Positive momentum</VisuallyHidden>}
      {hasMomentum === false && <VisuallyHidden>Delay warning</VisuallyHidden>}
    </Button>
  );
};

export default FloorNameCell;
