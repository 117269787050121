import { AxiosRequestConfig } from 'axios';

import {
  HierarchyProgressTrackingTableData,
  ProgressTrackingFloorplanProgressHistory,
  ProgressTrackingHierarchyNodeProgressHistory,
  ProgressTrackingMilestoneProgressHistory,
  ProgressTrackingTableData,
} from '../../../@types/api/v1/bespoke/ProgressTracking';
import { get } from '../../next';

export const ProgressTrackingApi = {
  /**
   * Retrieve the current progress data (with momentum) for a project.
   *
   * @param projectId The ID of the project.
   * @param date Date to request progress data up to, formatted as an RFC 3339 timestamp. For example,
   * `"2024-10-18T21:25:31.785Z"`.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the progress data for the project.
   */
  getProgressData: (projectId: number, date: string, requestConfig?: AxiosRequestConfig) => {
    const queryParams = new URLSearchParams();
    queryParams.set('date', date);

    return get<ProgressTrackingTableData>(
      `api/v1/bespoke/progress-tracking/${projectId}/progress-v2/?${queryParams.toString()}`,
      requestConfig
    );
  },
  /**
   * Retrieve the current progress data for a project at a hierarchy node.
   * @param hierarchyNodeId The ID of the hierarchy node.
   * @param date Date to request progress data up to, formatted as an RFC 3339 timestamp. For example,
   * `"2024-10-18T21:25:31.785Z"`.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the progress data for the project at the hierarchy node.
   */
  getHierarchyProgressData: (hierarchyNodeId: number, date: string, requestConfig?: AxiosRequestConfig) => {
    const queryParams = new URLSearchParams();
    queryParams.set('date', date);

    return get<HierarchyProgressTrackingTableData>(
      `api/v1/bespoke/progress-tracking-momentum/hierarchy-node/${hierarchyNodeId}/progress/?${queryParams.toString()}`,
      requestConfig
    );
  },
  /**
   * Get the progress history for a milestone within a project.
   * @param projectId The ID of the project.
   * @param milestoneId The ID of the milestone.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the progress history for the milestone.
   */
  getTotalMilestoneProgressHistory: (projectId: number, milestoneId: number, requestConfig?: AxiosRequestConfig) =>
    get<ProgressTrackingMilestoneProgressHistory>(
      `api/v1/bespoke/progress_tracking/${projectId}/milestone/${milestoneId}/progress-history/`,
      requestConfig
    ),
  /**
   * Get the total progress history for a hierarchy node.
   * @param hierarchyNodeId The ID of the hierarchy node.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the progress history for the hierarchy node.
   */
  getTotalHierarchyNodeProgressHistory: (hierarchyNodeId: number, requestConfig?: AxiosRequestConfig) =>
    get<ProgressTrackingHierarchyNodeProgressHistory>(
      `api/v1/bespoke/progress-tracking/hierarchy-node/${hierarchyNodeId}/progress-history/`,
      requestConfig
    ),
  /**
   * Get the total progress history for a floorplan.
   * @param floorplanId The ID of the floorplan.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the progress history for the floorplan.
   */
  getTotalFloorplanProgressHistory: (floorplanId: number, requestConfig?: AxiosRequestConfig) =>
    get<ProgressTrackingFloorplanProgressHistory>(
      `/api/v1/bespoke/progress-tracking/floorplan/${floorplanId}/progress-history/`,
      requestConfig
    ),
  /**
   * Get the progress history for a milestone at a hierarchy node.
   * @param hierarchyNodeId The ID of the hierarchy node.
   * @param milestoneId The ID of the milestone.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the progress history for the milestone at the hierarchy node.
   */
  getHierarchyNodeMilestoneProgressHistory: (
    hierarchyNodeId: number,
    milestoneId: number,
    requestConfig?: AxiosRequestConfig
  ) =>
    get<ProgressTrackingMilestoneProgressHistory>(
      `api/v1/bespoke/progress-tracking/hierarchy-node/${hierarchyNodeId}/milestone/${milestoneId}/progress-history/`,
      requestConfig
    ),
  /**
   * Get the progress history for a milestone, on a floorplan, within a project.
   * @param projectId The ID of the project.
   * @param milestoneId The ID of the milestone.
   * @param floorplanId The ID of the floorplan.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the progress history for a milestone on the floorplan.
   */
  getFloorMilestoneProgressHistory: (
    projectId: number,
    milestoneId: number,
    floorplanId: number,
    requestConfig?: AxiosRequestConfig
  ) => {
    const queryParams = new URLSearchParams();
    queryParams.set('floorplan_id', String(floorplanId));
    queryParams.set('milestone_id', String(milestoneId));

    return get<ProgressTrackingMilestoneProgressHistory>(
      `api/v1/bespoke/progress_tracking/${projectId}/floorplan_milestone_progress_history/?${queryParams.toString()}`,
      requestConfig
    );
  },
  /**
   * Retrieve an export of the progress data for a project as of a date. At the moment, the API only sends a response in
   * CSV format.
   * @param projectId The ID of the project.
   * @param date The date to request data for. Expected to be a RFC 3339 timestamp.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping a `Blob` of data.
   */
  getProgressDataExport: (projectId: number, date: string, requestConfig?: AxiosRequestConfig) => {
    const queryParams = new URLSearchParams();
    queryParams.set('date', date);

    return get<Blob>(`/api/v1/bespoke/progress_tracking/${projectId}/progress/export/?${queryParams.toString()}`, {
      responseType: 'blob',
      ...requestConfig,
    });
  },
};
