import { AxiosRequestConfig } from 'axios';

import { get } from '../../next';

export interface TargetWalkthroughResponse {
  /** The floorplan + walkthrough IDs the server decreed is the one to go to */
  floorplan_id: number | null;
  walkthrough_id: number | null;
}

export const ProjectTo360Api = {
  /**
   * Given the project ID, get a floorplan ID and walkthrough ID.  The server will do some "business logic" to
   * determine which floorplan should be returned.
   *
   * At the time of this writing, the rules are:
   * - Prefer a floorplan with `site` in the name
   * - Second choice, find the floorplan with the latest visible walkthrough
   * - If there aren't any floors with walks, the values will be null.
   *
   * @param projectId The id of the project
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns TargetWalkthroughResponse
   */
  getTargetWalkthroughFor360: (projectId: number, requestConfig?: AxiosRequestConfig) =>
    get<TargetWalkthroughResponse>(`/api/v1/bespoke/projects/${projectId}/target_walkthrough/`, requestConfig),
};
