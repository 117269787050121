import { Button, Flex, Icon, Progress, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import { MouseEvent } from 'react';

import { getRoundedProgressValue } from '../../utils/progressUtils';
import { ProgressTrackingIcon } from '../Icon';

interface EstimatedProgressProps {
  /** Handler for clicking the progress tracking button. If this and `projectId` are provided,
   * the link to the progress tracking page will be rendered. */
  onViewProgress?: (event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
  /** The progress value to display as a percentage and in a progress bar. */
  progress: number | null;
  /** The size of the progress bar, for use with the Chakra theme. */
  progressBarSize?: 'sm' | 'lg';
  /**
   * Optional URL to render in the "View Progress" button. If this and
   * `onViewProgress` are defined, a link to the Progress Tracking page will be rendered.
   * Otherwise, the button will not function as a link.
   */
  progressTrackingUrl?: string;
}

export const EstimatedProgress = ({
  progress,
  progressBarSize = 'sm',
  progressTrackingUrl,
  onViewProgress,
}: EstimatedProgressProps) => {
  const roundedProgress = getRoundedProgressValue(progress);

  const displayValue = typeof progress === 'number' && Number.isFinite(progress) ? `${roundedProgress}%` : '-';

  return (
    <>
      <Stat>
        <Flex alignItems="center" gap="1rem" justifyContent="space-between">
          <StatLabel>Estimated Progress</StatLabel>
          {onViewProgress &&
            (progressTrackingUrl ? (
              <Button
                as="a"
                href={progressTrackingUrl}
                leftIcon={<Icon as={ProgressTrackingIcon} height="1.25rem" width="1.25rem" />}
                size="sm"
                variant="mediumEmphasisV2"
                onClick={onViewProgress}
              >
                View Progress
              </Button>
            ) : (
              <Button
                leftIcon={<Icon as={ProgressTrackingIcon} height="1.25rem" width="1.25rem" />}
                size="sm"
                variant="mediumEmphasisV2"
                onClick={onViewProgress}
              >
                View Progress
              </Button>
            ))}
        </Flex>
        <StatNumber>{displayValue}</StatNumber>
      </Stat>
      <Progress value={roundedProgress ?? 0} variant="purpleProgress" size={progressBarSize} />
    </>
  );
};
