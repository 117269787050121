import { Button, FormErrorMessage, FormHelperText, FormLabel, Input, VisuallyHidden } from '@chakra-ui/react';
import classNames from 'classnames';
import { ErrorMessage, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import capitalize from 'lodash/capitalize';
import { ChangeEvent, ReactElement, useRef, useState } from 'react';
import * as Yup from 'yup';

import api from '../../api';
import { OnboardingStep } from '../../containers/Onboarding';
import { transformErrorResponse } from '../../utils/error';
import { getPasswordValidationSchema, passwordRequirements } from '../../utils/validation';
import FormControl from '../FormControl';
import { CheckIcon, DotIcon, ErrorIcon } from '../Icon';

import classes from './Onboarding.module.scss';

interface Props {
  onboardingToken?: string;
  nextAction: (next: OnboardingStep) => void;
  setData: (data: FormValues) => void;
}

export interface FormValues {
  password: string;
  confirm_password: string;
}

const validationSchema = Yup.object({
  password: getPasswordValidationSchema('Password'),
  confirm_password: Yup.string()
    .required('Password confirmation required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});

const PasswordForm = ({ nextAction, onboardingToken, setData }: Props): ReactElement => {
  const [passwordValidationMessages, setPasswordValidationMessages] = useState<string[] | undefined>();
  const [submitError, setSubmitError] = useState<string | undefined>();

  const formRef = useRef<FormikProps<FormValues>>(null);

  async function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      await api.userToken.setPassword(onboardingToken, values);

      setPasswordValidationMessages(undefined);
      setData(values);
      nextAction(OnboardingStep.FINAL);
    } catch (error) {
      console.error('[Onboarding] Failed to set password!', error);
      const { message, fieldErrors } = await transformErrorResponse(
        error as Response,
        values,
        'Failed to set password. Please try again later.'
      );
      setSubmitError(message);
      if (fieldErrors) {
        formikHelpers.setErrors(fieldErrors);
      }
    }
  }

  async function onChangePassword(
    event: ChangeEvent<HTMLInputElement>,
    formikChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void
  ): Promise<void> {
    formikChangeHandler(event);
    await updatePasswordValidationMessages(event.target.value);
  }

  async function onSubmit() {
    // Note: we purposely don't prevent the default submit behavior here.
    await updatePasswordValidationMessages(formRef.current?.values?.password);
  }

  async function updatePasswordValidationMessages(value = '') {
    try {
      await validationSchema.validateAt('password', { password: value, confirm_password: '' }, { abortEarly: false });
      setPasswordValidationMessages([]);
    } catch (error) {
      const validationError = error as Yup.ValidationError;
      setPasswordValidationMessages(validationError.errors);
    }
  }

  return (
    <Formik
      initialValues={{
        password: '',
        confirm_password: '',
      }}
      innerRef={formRef}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
        <Form className={classes.form}>
          <FormControl isInvalid={Boolean(errors.password) && touched.password} variant="external">
            <VisuallyHidden as={FormLabel}>Password</VisuallyHidden>
            <Input
              aria-label="Password"
              autoComplete="new-password"
              name="password"
              onBlur={handleBlur}
              onChange={(event) => onChangePassword(event, handleChange)}
              placeholder="Password"
              type="password"
              value={values.password}
            />
            <FormHelperText className={classes.passwordHelpText}>
              {passwordRequirements.map((requirement) => {
                let requirementStatus = 'indeterminate';
                if (passwordValidationMessages?.some((message) => message.endsWith(requirement))) {
                  requirementStatus = 'failed';
                } else if (passwordValidationMessages) {
                  requirementStatus = 'passed';
                }

                const requirementClassNames = classNames(classes.requirement, {
                  [classes.requirementIndeterminate]: requirementStatus === 'indeterminate',
                  [classes.requirementPassed]: requirementStatus === 'passed',
                  [classes.requirementFailed]: requirementStatus === 'failed',
                });
                return (
                  <div
                    className={requirementClassNames}
                    data-testid={`password-requirement-${requirementStatus}`}
                    key={requirement}
                  >
                    {requirementStatus === 'indeterminate' && <DotIcon aria-hidden />}
                    {requirementStatus === 'failed' && <ErrorIcon aria-label="Requirement failed" />}
                    {requirementStatus === 'passed' && <CheckIcon aria-label="Requirement passed" />}
                    <span>{capitalize(requirement)}</span>
                  </div>
                );
              })}
            </FormHelperText>
            {/* Only show an error message if it's distinct from all other requirement messages */}
            {passwordRequirements.every((requirement) => !errors.password?.includes(requirement)) && (
              <ErrorMessage component={FormErrorMessage} name="password" />
            )}
          </FormControl>
          <FormControl isInvalid={Boolean(errors.confirm_password) && touched.confirm_password} variant="external">
            <VisuallyHidden as={FormLabel}>Confirm password</VisuallyHidden>
            <Input
              autoComplete="new-password"
              name="confirm_password"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Confirm password"
              type="password"
              value={values.confirm_password}
            />
            <ErrorMessage component={FormErrorMessage} name="confirm_password" />
          </FormControl>
          {!isSubmitting && submitError && <p className={classes.errorMessage}>{submitError}</p>}
          <Button
            isLoading={isSubmitting}
            marginBlockEnd="2.5rem"
            onClick={onSubmit}
            size="lg"
            type="submit"
            variant="primaryExternal"
          >
            Continue
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordForm;
