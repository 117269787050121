import { Button, Checkbox, FormErrorMessage, FormLabel, Input, VisuallyHidden } from '@chakra-ui/react';
import { ErrorMessage, Form, Formik, FormikHelpers } from 'formik';
import Cookies from 'js-cookie';
import { MouseEvent, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { Store } from '../../@types/redux/store';
import { login } from '../../actions/auth';
import { getAuthToken as getProcoreAuth } from '../../actions/procore';
import api from '../../api';
import { MultiStepData } from '../../containers/Login';
import { transformErrorResponse } from '../../utils/error';
import FormControl from '../FormControl';

import classes from './Login.module.scss';

interface FormValues {
  mfa_code: string;
  trusted_device: boolean;
}

interface Props {
  multiStepData?: MultiStepData;
}

const validationSchema = Yup.object({
  mfa_code: Yup.string().required('Please enter code sent to your email'),
  trusted_device: Yup.boolean(),
});

const MultiFactorAuthForm = (props: Props): ReactElement => {
  const [wasResent, setWasResent] = useState(false);
  const [errorSendingCode, setErrorSendingCode] = useState<string>();
  const [submitError, setSubmitError] = useState<string>();

  const dispatch = useDispatch();
  const fromLocation = useSelector((state: Store) => state.app.fromLocation);

  const history = useHistory();

  async function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    try {
      const jwtRes = await api.auth.jwtLogin({
        email: props.multiStepData?.email,
        password: props.multiStepData?.password,
        mfa_code: values.mfa_code,
        trust_device: values.trusted_device,
      });

      Cookies.set('onsitevision_access', jwtRes.access, { domain: window.location.hostname, expires: 1 });
      Cookies.set('onsitevision_refresh', jwtRes.refresh, { domain: window.location.hostname, expires: 30 });
      Cookies.set('trusted_device', jwtRes.trusted_device_code, { domain: window.location.hostname, expires: 120 });

      const userProfile = await api.auth.me();
      dispatch(login(userProfile));
      dispatch(getProcoreAuth());

      if (jwtRes.first_login && !jwtRes.is_from_onboarding) {
        history.push('/set-password');
      } else {
        history.push(fromLocation ?? '/');
      }
    } catch (error) {
      console.error('[MultiFactorAuthForm] Failed to submit verification code!', error);
      const { message, fieldErrors } = await transformErrorResponse(
        error as Response,
        values,
        'Failed to log in. Please try again later.'
      );
      setSubmitError(message);
      if (fieldErrors) {
        formikHelpers.setErrors(fieldErrors);
      }
    }
  }

  async function handleResend(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    try {
      await api.auth.jwtLogin({
        email: props.multiStepData?.email,
        password: props.multiStepData?.password,
      });
      setWasResent(true);
    } catch (error) {
      console.error('[MultiFactorAuthForm] Failed to resend code!', error);
      setErrorSendingCode('Failed to send new verification code. Please try again later.');
    }
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (wasResent) {
      const timeout = setTimeout(() => setWasResent(false), 5000);
      return () => clearTimeout(timeout);
    }
  }, [wasResent]);

  return (
    <>
      <h2>Enter verification code</h2>
      <Formik
        initialValues={{
          mfa_code: '',
          trusted_device: false,
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, handleBlur, handleChange, isSubmitting, setFieldValue, touched, values }) => (
          <Form className={classes.form}>
            <FormControl isInvalid={Boolean(errors.mfa_code) && touched.mfa_code} variant="external">
              <VisuallyHidden as={FormLabel}>Verification code</VisuallyHidden>
              <Input
                name="mfa_code"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Verification code"
                value={values.mfa_code}
              />
              <ErrorMessage component={FormErrorMessage} name="mfa_code" />
            </FormControl>
            <FormControl>
              <Checkbox
                checked={values.trusted_device}
                name="trusted_device"
                onBlur={handleBlur}
                onChange={(e) => setFieldValue('trusted_device', e.target.checked)}
              >
                This is a trusted device. Don’t ask me for a code in the future.
              </Checkbox>
            </FormControl>
            {!isSubmitting && submitError && <p className={classes.errorMessage}>{submitError}</p>}
            <Button
              isLoading={isSubmitting}
              marginBlockEnd="2.5rem"
              size="lg"
              type="submit"
              variant="primaryExternal"
              width="12.5rem"
            >
              Sign in
            </Button>
            {errorSendingCode && <p className={classes.errorMessage}>{errorSendingCode}</p>}
            {wasResent && !errorSendingCode && <p>Code Resent</p>}
            {!wasResent && !errorSendingCode && (
              <p>
                No email received? {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" onClick={handleResend}>
                  Resend Code
                </a>
              </p>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MultiFactorAuthForm;
