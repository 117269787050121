import { Content, InternalLayout, Pane, PaneBody, PaneHead } from '../components';
import PageController from '../components/ControlCenter/PageController';
import IntegrationSettings from '../components/Settings/IntegrationSettings';
import NotificationSettings from '../components/Settings/NotificationSettings';
import ProfileSettings from '../components/Settings/ProfileSettings';

const Settings = () => {
  return (
    <InternalLayout>
      <Content applyGutters>
        <PageController title="Settings" />
        <Pane>
          <PaneHead headingLevel={2} title="Profile and Security" />
          <PaneBody>
            <ProfileSettings />
          </PaneBody>
        </Pane>
        <Pane>
          <PaneHead headingLevel={2} title="App Integrations" />
          <PaneBody>
            <IntegrationSettings />
          </PaneBody>
        </Pane>
        <Pane>
          <PaneHead headingLevel={2} title="Notification Preferences" />
          <PaneBody>
            <NotificationSettings />
          </PaneBody>
        </Pane>
      </Content>
    </InternalLayout>
  );
};

export default Settings;
