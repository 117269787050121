import { generatePath } from 'react-router-dom';

import Routes from '../routes';

/**
 * Generate a destination URL given a project ID, floorplan ID, and optionally, walkthrough and node IDs.
 *
 * - If node ID and walkthrough ID are provided, the URL will link to the "View 360" page.
 * - If a walkthrough ID is provided without a node ID, the URL will link to the "Walkthrough" page (i.e. a floorplan
 *   with the walkthrough's nodes superimposed).
 * - If no walkthrough ID is provided, the URL will link to the "Floorplan" page.
 */
export const generateProjectPageUrl = (
  projectId: number,
  floorplanId: number,
  walkthroughId?: number,
  nodeId?: string
) => {
  if (walkthroughId && nodeId) {
    return generatePath(Routes.VIEW_360, { projectId, floorplanId, walkthroughId, nodeId });
  }

  if (walkthroughId) {
    return generatePath(Routes.WALKTHROUGH, { projectId, floorplanId, walkthroughId });
  }

  return generatePath(Routes.FLOORPLAN, { projectId, floorplanId });
};

export const generateProgressTrackingPageUrl = (projectId: number) => {
  // todo: look up how to optionally append date query param
  return generatePath(Routes.PROJECT_PROGRESS_TRACKING, { id: projectId });
};
