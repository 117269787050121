import { useToast } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { Project } from '../../../@types/api/v0/rest/Project';
import { ProgressTrackingHierarchyNodeProgressHistory } from '../../../@types/api/v1/bespoke/ProgressTracking';
import { ProjectHierarchyNode } from '../../../@types/api/v1/bespoke/ProjectHierarchy';
import { UserManagementApi } from '../../../api/next';
import { ProgressTrackingApi } from '../../../api/v1/bespoke/ProgressTrackingApi';
import { HierarchyNodeApi } from '../../../api/v1/rest/HierarchyNodeApi';
import Toast from '../../../components/Toast';
import {
  ProgressTrackingQueryKeys,
  ProjectQueryKeys,
  QueryTopics,
  UserManagementQueryKeys,
} from '../../../constants/queries';
import { HierarchyProgressDrawer } from './HierarchyProgressDrawer';

export interface HierarchyProgressDrawerContainerProps {
  /** Flag indicating whether or not the drawer is open. */
  isOpen?: boolean;
  /** Handler to call when the user wants to close the drawer. */
  onClose: () => void;
  /** The current project. */
  project: Project;
  /** The currently-selected Time Travel date. */
  selectedDate?: Date;
  /** The hierarchy node to display in the drawer. */
  hierarchyNode?: ProjectHierarchyNode;
  /** Optional function to call when a hierarchy node is selected in the drawer. */
  onHierarchyNodeSelect?: (hierarchyNode?: ProjectHierarchyNode) => void;
  /** Flag indicating whether or not the hierarchy node has momentum. */
  hasMomentum?: boolean | null;
}

const HierarchyProgressDrawerContainer = (props: HierarchyProgressDrawerContainerProps) => {
  const { isOpen, onClose, project, selectedDate, hierarchyNode, onHierarchyNodeSelect, hasMomentum } = props;

  const location: ProjectHierarchyNode | undefined = hierarchyNode;
  const locationType = 'HIERARCHY_NODE_TOTALS';

  const toast = useToast();
  const queryClient = useQueryClient();

  const meQuery = useQuery({
    queryKey: [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.ME],
    queryFn: async () => (await UserManagementApi.getMe()).data,
  });

  const queryKey = [
    QueryTopics.PROGRESS_TRACKING,
    ProgressTrackingQueryKeys.PROGRESS_HISTORY,
    project.id,
    locationType,
    location?.id,
    selectedDate,
  ];
  const progressHistoryQuery = useQuery({
    enabled: Boolean(isOpen),
    queryKey,
    queryFn: async ({ signal }) => {
      const promise: Promise<AxiosResponse<ProgressTrackingHierarchyNodeProgressHistory>> =
        ProgressTrackingApi.getTotalHierarchyNodeProgressHistory(hierarchyNode?.id ?? -1, {
          signal,
        });

      return (await promise).data;
    },
  });

  const updateDateMutation = useMutation({
    // TODO: should this still be under the PROJECTS QueryTopic?
    mutationKey: [
      QueryTopics.PROJECTS,
      ProjectQueryKeys.PROJECT_HIERARCHY_NODE_DATE_UPDATE,
      project.id,
      hierarchyNode?.id,
    ],
    mutationFn: ({ newDate }: { newDate: string }) => {
      return HierarchyNodeApi.updateHierarchyNodeDate(hierarchyNode?.id ?? -1, { end_date: newDate });
    },
    onError: (error) => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => {
          if (error instanceof AxiosError && error.response?.status === 403) {
            return (
              <Toast
                {...props}
                title="Error"
                description="You do not have permission to set this date. Contact Customer Success for assistance."
                status="error"
              />
            );
          }
          return <Toast {...props} title="Error" description="An error occurred. Try again later." status="error" />;
        },
      });
    },
    onSuccess: () => {
      // Changes to a given baseline completion date (BCD) for the root hierarchy node impact the BCDs in the "All
      // floorplans" view and vice-versa. For this reason, we need to reset all progress history for the milestone. This
      // causes the current query to refetch.
      queryClient.resetQueries({
        queryKey: [
          QueryTopics.PROGRESS_TRACKING,
          ProgressTrackingQueryKeys.PROGRESS_HISTORY,
          project.id,
          // Intentionally omitted:
          // locationType,
          // location?.id,
        ],
        exact: false,
      });

      // Clear all Progress Tracking data for the project as well, just to ensure nothing is stale. This has to happen
      // because the BCDs for the root node and "All floorplans" mode are the synchronized, but also because data for
      // other Time Travel dates cannot be assumed to be valid anymore either, since the BCDs are used to calculate
      // momentum.
      queryClient.resetQueries({
        exact: false,
        queryKey: [
          QueryTopics.PROGRESS_TRACKING,
          ProgressTrackingQueryKeys.PROGRESS_TABLE_DATA,
          project.id,
          // Intentionally omitted:
          // hierarchyNode?.id,
          // selectedDate,
        ],
      });

      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => (
          <Toast {...props} title="Success" description="Baseline completion date updated." status="success" />
        ),
      });

      onClose();
    },
  });

  const canEditMilestoneCompletionDate = (meQuery.data?.set_trade_dates ?? []).some(
    (account) => account.id === project.account.id
  );

  const isProgressTrackingEnabled = project.executive_dashboard_enabled;

  const progressValue =
    progressHistoryQuery.data?.progress_values && progressHistoryQuery.data.progress_values.length > 0
      ? progressHistoryQuery.data?.progress_values[progressHistoryQuery.data.progress_values.length - 1].value
      : undefined;

  return (
    <HierarchyProgressDrawer
      canEditCompletionDate={canEditMilestoneCompletionDate}
      hasMomentum={hasMomentum}
      isError={progressHistoryQuery.isError}
      isFetching={progressHistoryQuery.isFetching}
      isOpen={Boolean(isOpen)}
      isCompletionDateUpdating={updateDateMutation.isLoading}
      isProgressTrackingEnabled={isProgressTrackingEnabled}
      progressHistory={progressHistoryQuery.data}
      onClose={onClose}
      onCompletionDateChange={(newDate: string) => updateDateMutation.mutate({ newDate })}
      selectedDate={selectedDate}
      progressValue={progressValue}
      hierarchyNode={hierarchyNode}
      onHierarchyNodeSelect={onHierarchyNodeSelect}
    />
  );
};

export default HierarchyProgressDrawerContainer;
