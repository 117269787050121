import { Button, FormErrorMessage, FormLabel, Input, VisuallyHidden } from '@chakra-ui/react';
import { ErrorMessage, Form, Formik, FormikHelpers } from 'formik';
import Cookies from 'js-cookie';
import { ReactElement, useEffect, useState } from 'react';
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { Store } from '../../@types/redux/store';
import { login } from '../../actions/auth';
import { getAuthToken as getProcoreAuth } from '../../actions/procore';
import api from '../../api';
import { AuthStep, MultiStepData } from '../../containers/Login';
import Routes from '../../routes';
import { transformErrorResponse } from '../../utils/error';
import FormControl from '../FormControl';

import classes from './Login.module.scss';

const PROCORE_AUTH_URL = window.PROCORE_API_PREFIX;
const PROCORE_CLIENT_ID = window.PROCORE_CLIENT_ID;
const PROCORE_REDIRECT_URI = window.PROCORE_LOGIN_CALLBACK;

const GOOGLE_CLIENT_ID = window.LOGIN_GOOGLE_CLIENT_ID;

interface FormValues {
  email: string;
  password: string;
}

interface LoginFormProps {
  setAuthStep: (step: AuthStep) => void;
  setMultiStepData: (multiStepData: MultiStepData) => void;
}

function isGoogleLoginResponse(
  response: GoogleLoginResponse | GoogleLoginResponseOffline
): response is GoogleLoginResponse {
  return (response as GoogleLoginResponse).getBasicProfile !== undefined;
}

const validationSchema = Yup.object({
  email: Yup.string().email('Provide a valid email address').required('Email address is required'),
  password: Yup.string().required('Password is required'),
});

const LoginForm = (props: LoginFormProps): ReactElement => {
  const [loginError, setLoginError] = useState<string | undefined>();

  const dispatch = useDispatch();
  const fromLocation = useSelector((state: Store) => state.app.fromLocation);

  const history = useHistory();
  const location = useLocation();

  async function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    setLoginError(undefined);
    try {
      const jwtRes = await api.auth.jwtLogin({
        ...values,
        trusted_device_code: Cookies.get('trusted_device'),
      });
      if (jwtRes.need_mfa) {
        props.setMultiStepData(values);
        props.setAuthStep(AuthStep.MultiFactor);
        return;
      }
      Cookies.set('onsitevision_access', jwtRes.access, { domain: window.location.hostname, expires: 1 });
      Cookies.set('onsitevision_refresh', jwtRes.refresh, { domain: window.location.hostname, expires: 30 });

      const userProfile = await api.auth.me();
      dispatch(login(userProfile));
      dispatch(getProcoreAuth());

      if (jwtRes.first_login && !jwtRes.is_from_onboarding) {
        history.push(Routes.SET_PASSWORD);
      } else {
        history.push(fromLocation ?? Routes.PROJECTS);
      }
    } catch (error) {
      const { message, fieldErrors } = await transformErrorResponse(
        error as Response,
        values,
        'Failed to log in. Please try again later.'
      );
      setLoginError(message);
      if (fieldErrors) {
        formikHelpers.setErrors(fieldErrors);
      }
    }
  }

  async function onGoogleLogin(response: GoogleLoginResponse | GoogleLoginResponseOffline) {
    if (!isGoogleLoginResponse(response)) {
      setLoginError('Failed to login with Google. Please try again later.');
      return;
    }

    try {
      const googleIdToken = response.getAuthResponse()?.id_token;
      const googleLoginResponse = await api.auth.loginWithGoogle(googleIdToken);
      const tokens = await api.auth.getJWTfromOTT(googleLoginResponse.auth_token);
      Cookies.set('onsitevision_access', tokens.access, { domain: window.location.hostname, expires: 1 });
      Cookies.set('onsitevision_refresh', tokens.refresh, { domain: window.location.hostname, expires: 30 });
      const userProfile = await api.auth.me();

      dispatch(login(userProfile));
      dispatch(getProcoreAuth());
      history.push(fromLocation ?? '/');
    } catch (error) {
      console.error('[LoginForm] Failed to login with Google!', error);
      setLoginError('Something went wrong. Please try again later.');
    }
  }

  function onFailedGoogleLogin() {
    setLoginError('Failed to login with Google. Please try again later.');
  }

  function redirectToProcore() {
    const uri =
      `${PROCORE_AUTH_URL}oauth/authorize` +
      `?client_id=${PROCORE_CLIENT_ID}&response_type=code&redirect_uri=${PROCORE_REDIRECT_URI}`;
    window.location.href = encodeURI(uri);
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const errorMessage = queryParams.get('errormsg');
    if (!errorMessage) {
      return;
    }

    setLoginError(errorMessage);
    queryParams.delete('errormsg');
    history.replace({
      search: queryParams.toString(),
    });
  }, [history, location]);

  return (
    <>
      <h2>Sign in with OnsiteIQ</h2>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
          <Form className={classes.form}>
            <FormControl isInvalid={Boolean(errors.email) && touched.email} variant="external">
              <VisuallyHidden as={FormLabel}>Email address</VisuallyHidden>
              <Input
                autoComplete="email"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Email address"
                type="email"
                value={values.email}
              />
              <ErrorMessage component={FormErrorMessage} name="email" />
            </FormControl>
            <FormControl isInvalid={Boolean(errors.password) && touched.password} variant="external">
              <VisuallyHidden as={FormLabel}>Password</VisuallyHidden>
              <Input
                autoComplete="current-password"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Password"
                type="password"
                value={values.password}
              />
              <ErrorMessage component={FormErrorMessage} name="password" />
            </FormControl>
            <Link className={classes.forgotPasswordLink} to={Routes.FORGOT_PASSWORD}>
              Forgot Password?
            </Link>
            {!isSubmitting && loginError && <p className={classes.errorMessage}>{loginError}</p>}
            <Button
              isLoading={isSubmitting}
              marginBlockEnd="2.5rem"
              size="lg"
              type="submit"
              variant="primaryExternal"
              width="12.5rem"
            >
              Sign in
            </Button>
          </Form>
        )}
      </Formik>
      <div className={classes.divider}>
        <hr />
        <span>or</span>
        <hr />
      </div>
      <h2>Sign in with partners</h2>
      {/* Note: using image tags for the icons below allows the browser to cache the images and (slightly) reduces
          the bundle size */}
      <div className={classes.partnerLogins}>
        <GoogleLogin
          clientId={GOOGLE_CLIENT_ID}
          cookiePolicy="single_host_origin"
          onFailure={onFailedGoogleLogin}
          onSuccess={onGoogleLogin}
          render={({ onClick }) => (
            <Button
              data-pendo-label="Google sign in"
              data-pendo-topic="social"
              justifyContent="flex-start"
              leftIcon={<img alt="" aria-hidden={true} src="/resources/logo-google.svg" />}
              onClick={onClick}
              paddingInline="1rem"
              size="lg"
              variant="outline"
            >
              Sign in with Google
            </Button>
          )}
        />
        <Button
          data-pendo-label="Procore sign in"
          data-pendo-topic="social"
          justifyContent="flex-start"
          leftIcon={<img alt="" aria-hidden={true} src="/resources/logo-procore.svg" />}
          onClick={redirectToProcore}
          paddingInline="1rem"
          size="lg"
          variant="outline"
        >
          Sign in with Procore
        </Button>
      </div>
    </>
  );
};

export default LoginForm;
