import { AxiosRequestConfig } from 'axios';

import { Floorplan } from '../../../@types/api/v1/rest/Floorplan';
import { get, patch } from '../../next';

/**
 * Request shape used when updating milestone-related dates for a floorplan. The only dates that are currently supported
 * are baseline completion dates, i.e. end dates.
 */
export interface FloorplanMilestoneDateUpdateRequestBody {
  /** ID of the milestone. */
  milestone_id: number;
  /**
   * Projected date on which the given milestone/trade is expected to be completed for the floorplan. This is the date
   * portion (i.e. everything up to the "T") of a RFC 3339 timestamp.
   *
   * @example "2024-10-18"
   */
  end_date: string;
}

/**
 * Response shape returned when a milestone's dates are successfully set for a floorplan. Note that this is currently the
 * same shape as the request.
 */
export type FloorplanMilestoneDateUpdateResponseBody = FloorplanMilestoneDateUpdateRequestBody;

/**
 * Request shape used when updating dates for a floorplan. The only dates that are currently supported
 * are baseline completion dates, i.e. end dates.
 */
export interface FloorplanBaselineCompletionDateUpdateRequestBody {
  /**
   * Projected date on which the given floorplan is expected to be completed. This is the date
   * portion (i.e. everything up to the "T") of a RFC 3339 timestamp.
   *
   * @example "2024-10-18"
   */
  end_date: string;
}

/**
 * Response shape returned when dates are successfully set for a floorplan. Note that this is currently the
 * same shape as the request.
 */
export type FloorplanBaselineCompletionDateUpdateResponseBody = FloorplanBaselineCompletionDateUpdateRequestBody;

export const FloorplanApi = {
  /**
   * Retrieve a floorplan instance by ID.
   * @param id Unique ID of the desired floorplan on the server.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the requested floorplan.
   */
  getById: (id: number, requestConfig?: AxiosRequestConfig) =>
    get<Floorplan>(`/api/v1/rest/floorplans/${id}/`, requestConfig),
  /**
   * Update a milestone/trade date entry for a given floorplan.
   * @param floorplanId The ID of the floorplan.
   * @param requestBody The milestone ID and date data to set on the server.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the updated floorplan's milestone date entry.
   */
  updateMilestoneDate: (
    floorplanId: number,
    requestBody: FloorplanMilestoneDateUpdateRequestBody,
    requestConfig?: AxiosRequestConfig
  ) =>
    patch<FloorplanMilestoneDateUpdateRequestBody, FloorplanMilestoneDateUpdateResponseBody>(
      `api/v1/rest/floorplans/${floorplanId}/milestone-dates/`,
      requestBody,
      requestConfig
    ),
  /**
   * Update the baseline completion date for a floorplan.
   * @param floorplanId The ID of the floorplan.
   * @param requestBody The date to set on the server.
   * @param requestConfig Optional Axios request configuration overrides.
   * @returns A promise wrapping the updated floorplan's date entry.
   */
  updateFloorplanBaselineCompletionDate: (
    floorplanId: number,
    requestBody: FloorplanBaselineCompletionDateUpdateRequestBody,
    requestConfig?: AxiosRequestConfig
  ) =>
    patch<FloorplanBaselineCompletionDateUpdateRequestBody, FloorplanBaselineCompletionDateUpdateResponseBody>(
      `api/v1/rest/floorplans/${floorplanId}/baseline-completion-date/`,
      requestBody,
      requestConfig
    ),
};
