// TODO: consolidate the progress drawers, especially this and the hierarchy node left side one
import { useToast } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { Floorplan } from '../../../@types/api/v0/rest/Floorplan';
import { Project } from '../../../@types/api/v0/rest/Project';
import { ProgressTrackingFloorplanProgressHistory } from '../../../@types/api/v1/bespoke/ProgressTracking';
import { UserManagementApi } from '../../../api/next';
import { ProgressTrackingApi } from '../../../api/v1/bespoke/ProgressTrackingApi';
import { FloorplanApi } from '../../../api/v1/rest/FloorplanApi';
import Toast from '../../../components/Toast';
import {
  ProgressTrackingQueryKeys,
  ProjectQueryKeys,
  QueryTopics,
  UserManagementQueryKeys,
} from '../../../constants/queries';
import { FloorplanProgressDrawer } from './FloorplanProgressDrawer';

export interface FloorplanProgressDrawerContainerProps {
  /** Flag indicating whether or not the drawer is open. */
  isOpen?: boolean;
  /** Handler to call when the user wants to close the drawer. */
  onClose: () => void;
  /** The current project. */
  project: Project;
  /** The currently-selected Time Travel date. */
  selectedDate?: Date;
  /** The floorplan to display in the drawer. */
  floorplan?: Floorplan;
  /** Flag indicating whether or not the floorplan has momentum. */
  hasMomentum?: boolean | null;
}

const FloorplanProgressDrawerContainer = (props: FloorplanProgressDrawerContainerProps) => {
  const { isOpen, onClose, project, selectedDate, floorplan, hasMomentum } = props;

  const location: Floorplan | undefined = floorplan;
  const locationType = 'FLOORPLAN_TOTALS';

  const toast = useToast();
  const queryClient = useQueryClient();

  const meQuery = useQuery({
    queryKey: [QueryTopics.USER_MANAGEMENT, UserManagementQueryKeys.ME],
    queryFn: async () => (await UserManagementApi.getMe()).data,
  });

  const queryKey = [
    QueryTopics.PROGRESS_TRACKING,
    ProgressTrackingQueryKeys.PROGRESS_HISTORY,
    project.id,
    locationType,
    location?.id,
    selectedDate,
  ];
  const progressHistoryQuery = useQuery({
    enabled: Boolean(isOpen),
    queryKey,
    queryFn: async ({ signal }) => {
      const promise: Promise<AxiosResponse<ProgressTrackingFloorplanProgressHistory>> =
        ProgressTrackingApi.getTotalFloorplanProgressHistory(floorplan?.id ?? -1, {
          signal,
        });

      return (await promise).data;
    },
  });

  const updateDateMutation = useMutation({
    mutationKey: [QueryTopics.PROJECTS, ProjectQueryKeys.PROJECT_FLOORPLAN_DATE_UPDATE, project.id, floorplan?.id],
    mutationFn: ({ newDate }: { newDate: string }) => {
      return FloorplanApi.updateFloorplanBaselineCompletionDate(floorplan?.id ?? -1, {
        end_date: newDate,
      });
    },
    onError: (error) => {
      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => {
          if (error instanceof AxiosError && error.response?.status === 403) {
            return (
              <Toast
                {...props}
                title="Error"
                description="You do not have permission to set this date. Contact Customer Success for assistance."
                status="error"
              />
            );
          }
          return <Toast {...props} title="Error" description="An error occurred. Try again later." status="error" />;
        },
      });
    },
    onSuccess: () => {
      // Changes to a given baseline completion date (BCD) for the root hierarchy node impact the BCDs in the "All
      // floorplans" view and vice-versa. For this reason, we need to reset all progress history for the milestone. This
      // causes the current query to refetch.
      queryClient.resetQueries({
        queryKey: [
          QueryTopics.PROGRESS_TRACKING,
          ProgressTrackingQueryKeys.PROGRESS_HISTORY,
          project.id,
          // Intentionally omitted:
          // locationType,
          // location?.id,
        ],
        exact: false,
      });

      // Clear all Progress Tracking data for the project as well, just to ensure nothing is stale. This has to happen
      // because the BCDs for the root node and "All floorplans" mode are the synchronized, but also because data for
      // other Time Travel dates cannot be assumed to be valid anymore either, since the BCDs are used to calculate
      // momentum.
      queryClient.resetQueries({
        exact: false,
        queryKey: [
          QueryTopics.PROGRESS_TRACKING,
          ProgressTrackingQueryKeys.PROGRESS_TABLE_DATA,
          project.id,
          // Intentionally omitted:
          // hierarchyNode?.id,
          // selectedDate,
        ],
      });

      toast({
        duration: 5000,
        isClosable: true,
        render: (props) => (
          <Toast {...props} title="Success" description="Baseline completion date updated." status="success" />
        ),
      });

      onClose();
    },
  });

  const canEditMilestoneCompletionDate = (meQuery.data?.set_trade_dates ?? []).some(
    (account) => account.id === project.account.id
  );

  const isProgressTrackingEnabled = project.executive_dashboard_enabled;

  const progressValue =
    progressHistoryQuery.data?.progress_values && progressHistoryQuery.data.progress_values.length > 0
      ? progressHistoryQuery.data?.progress_values[progressHistoryQuery.data.progress_values.length - 1].value
      : undefined;

  return (
    <FloorplanProgressDrawer
      canEditCompletionDate={canEditMilestoneCompletionDate}
      isCompletionDateUpdating={updateDateMutation.isLoading}
      isProgressTrackingEnabled={isProgressTrackingEnabled}
      hasMomentum={hasMomentum}
      isError={progressHistoryQuery.isError}
      isFetching={progressHistoryQuery.isFetching}
      isOpen={Boolean(isOpen)}
      progressHistory={progressHistoryQuery.data}
      onClose={onClose}
      onCompletionDateChange={(newDate: string) => updateDateMutation.mutate({ newDate })}
      selectedDate={selectedDate}
      progressValue={progressValue}
      floorplan={floorplan}
      projectId={project.id}
    />
  );
};

export default FloorplanProgressDrawerContainer;
